import {
  navigateToUrl,
  registerApplication,
  SingleSpaCustomEventDetail,
  start,
} from "single-spa";

singleSpaStart();

let isAuthenticated = false;

async function singleSpaStart() {
  const defaultUrl = "/learning/dashboard";
  const {
    auth$,
    getRefreshToken,
    refreshAuthTokens,
    isValidToken,
    STUDENT_LOGIN_URL,
    fetchStudent,
    getAuthenticatedUser,
    queryClient,
  } = await import("@edgenuity/cwng-student-api");

  registerApplication(
    "learning",
    // @ts-ignore
    () => import("@edgenuity/cwng-student-learning/single-spa"),
    (location) => isAuthenticated && location.pathname.startsWith("/learning")
  );

  registerApplication(
    "login",
    // @ts-ignore
    () => import("@edgenuity/cwng-student-login"),
    (location) => location.pathname.startsWith("/login")
  );
  if (!isValidToken()) {
    const refreshToken = getRefreshToken();

    if (refreshToken) {
      await refreshAuthTokens();
    }
  }
  auth$.next({
    error: null,
    isLoading: false,
    isAuthenticated: isValidToken(),
  });
  auth$.subscribe((value) => {
    if (value.isLoading) {
      return;
    }
    isAuthenticated = value.isAuthenticated;
    if (!isAuthenticated && value.logoutURL) {
      //redirects to external logout page
      navigateToUrl(value.logoutURL);
    }
    if (
      !isAuthenticated &&
      window.location.pathname !== "/login" &&
      !value.logoutURL
    ) {
      navigateToUrl(STUDENT_LOGIN_URL);
    }

    if (isAuthenticated && window.location.pathname === "/login") {
      navigateToUrl(defaultUrl);
    }
  });

  window.addEventListener(
    "single-spa:before-routing-event",
    (e: CustomEvent<SingleSpaCustomEventDetail>) => {
      if (!isAuthenticated && window.location.pathname !== "/login") {
        navigateToUrl(STUDENT_LOGIN_URL);
        return;
      }

      if (window.location.pathname === "/") {
        navigateToUrl(defaultUrl);
      }
    }
  );

  start();
}
